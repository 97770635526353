<script>
import Layout from "../../../layouts/main.vue";
import "@vueform/multiselect/themes/default.css";
import "prismjs";
import "prismjs/themes/prism.css";
import { Device } from '@twilio/voice-sdk';
import Cookies from 'js-cookie';

// import '../../../../public/js/twilio.min.js'
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            phoneInput: null,
            clientName: null,
            token: null,
            device: null,
            incomingCall: null,
            callFrom: null,
            call: null,
            inputVolumeBar: null,
            outputVolumeBar: null,
            speakerDevices: null,
            ringtoneDevices: null,
            selectedDevices: null,
            speakerdeviceList: [],
            ringtondeviceList: [],
            selectSoundDevice: null,
            selectRingDevice: null,
            isOpenTosted: false,
            isHangUpShow: false,
            isIncomingHangUp: false,
        };
    },
    components: {
        Layout,
    },
    computed: {
        ...mapGetters({
            userData: "auth/user",
        })
    },
    mounted() {

        const mobileAppCookie = Cookies.get('mobileapp');
        console.log('mobileappcookie is ',mobileAppCookie);

        if (mobileAppCookie === 'true') {
      document.getElementById('page-topbar').style.display = 'none';
      document.getElementsByClassName('app-menu').style.display = 'none';
    }


        this.getToken(`${this.userData.name.replace(" ","-")}~${this.userData.id}`);
        

        this.phoneInput = this.$route.params?.name

        console.log('this.phoneInput', this.$route.params?.name);

        // if (this.$route.params.name) {
        //     this.getToken(this.$route.params.name , true);
        // }else{
        //     this.phoneInput = null
        // }
    },
    methods: {
        ...mapActions({
            getTwilloToken: 'order/getTwilloToken',
            getVoiceResponse: 'order/getVoiceResponse',
        }),

        async makeCall() {
            this.isOpenTosted = true
            var params = {
                // get the phone number to call from the DOM
                to: this.phoneInput,
            };

            if (this.device) {
                console.log(`Attempting to call ${params.to} ...`);

                this.call = await new Device(this.token, {
                    logLevel: 1,
                    codecPreferences: ["opus", "pcmu"]
                }).connect({ params });

                this.call.on("accept", this.updateUIAcceptedOutgoingCall);
                this.call.on("disconnect", this.updateUIDisconnectedOutgoingCall);
                this.call.on("cancel", this.updateUIDisconnectedOutgoingCall);
            } else {
                console.log("Unable to make call.");
            }
        },

        updateUIAcceptedOutgoingCall(call) {
            console.log("Call in progress ...");
            this.bindVolumeIndicators(call);
            this.isHangUpShow = true
        },

        updateUIDisconnectedOutgoingCall() {
            console.log("Call disconnected.");
            this.isHangUpShow = false
            this.isOpenTosted = false
        },

        bindVolumeIndicators(call) {
            call.on("volume", function (inputVolume, outputVolume) {
                var inputColor = "red";
                if (inputVolume < 0.5) {
                    inputColor = "green";
                } else if (inputVolume < 0.75) {
                    inputColor = "yellow";
                }

                this.inputVolumeBar.style.width = Math.floor(inputVolume * 300) + "px";
                this.inputVolumeBar.style.background = inputColor;

                var outputColor = "red";
                if (outputVolume < 0.5) {
                    outputColor = "green";
                } else if (outputVolume < 0.75) {
                    outputColor = "yellow";
                }

                this.outputVolumeBar.style.width = Math.floor(outputVolume * 300) + "px";
                this.outputVolumeBar.style.background = outputColor;
            });
        },

        intitializeDevice() {
            this.device = new Device(this.token, {
                logLevel: 1,
                codecPreferences: ["opus", "pcmu"]
            });

            this.addDeviceListeners();
            this.device.register();
        },

        addDeviceListeners() {
            console.log("device", this.device);
            this.device.on("registered", () => {
                console.log("Twilio.Device Ready to make and receive calls!");
            });

            this.device.on("error", (error) => {
                console.log("Twilio.Device Error: " + error.message);
            });

            this.device.on("incoming", this.handleIncomingCall);
        },

        handleIncomingCall(call) {
            this.call = call
            console.log(`Incoming call from ${call.parameters.From}`);

            this.incomingCall = true
            this.callFrom = call.parameters.From

            call.on("cancel", this.handleDisconnectedIncomingCall);
            call.on("disconnect", this.handleDisconnectedIncomingCall);
            call.on("reject", this.handleDisconnectedIncomingCall);
        },

        acceptIncomingCall(call) {
            console.log("Accepted incoming call.");
            call.accept();
            this.isIncomingHangUp = true
        },
        rejectIncomingCall(call) {
            call.reject();
            console.log("Rejected incoming call");
            this.resetIncomingCallUI();
        },
        hangupIncomingCall(call) {
            call.disconnect();
            console.log("Hanging up incoming call");
            this.resetIncomingCallUI();
            this.isIncomingHangUp = false
        },

        handleDisconnectedIncomingCall() {
            console.log("Incoming call ended.");
            this.resetIncomingCallUI();
            this.isIncomingHangUp = false
        },

        resetIncomingCallUI() {
            this.callFrom = null;
            this.incomingCall = false
        },

        async getAudioDevices() {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            this.updateAllAudioDevices();
        },

        updateAllAudioDevices() {
            if (this.device) {
                this.speakerdeviceList = this.updateDevices();
                this.ringtondeviceList = this.updateDevices();
            }
        },
        updateOutputDevice() {
            this.device.audio.speakerDevices.set(this.selectSoundDevice);
        },

        updateRingtoneDevice() {
            this.device.audio.ringtoneDevices.set(this.selectRingDevice);
        },

        updateDevices() {
            let deviceList = [];
            this.device.audio.availableOutputDevices.forEach(function (device, id) {
                deviceList.push(
                    {
                        label: device.label,
                        dataId: id
                    }
                )
            });
            console.log("updateDevices", deviceList);
            return deviceList
        },
        getToken(name) {
            this.getTwilloToken({ name: name })
                .then(({ data }) => {
                    console.log(data);
                    this.token = data.token
                    // this.phoneInput = isInput ? data.identity : ''
                    this.intitializeDevice()
                    this.getAudioDevices()
                })
                .catch(
                    (e) => console.error(e)
                )
        }
  
    },

};
</script>

<template>
    <Layout>
        <div class="row">
            <!-- device select -->
            <div class="col-md-6">
                <div class="alert alert-primary" role="alert">
                    Your Calling ID is: {{`${this.userData.name.replace(" ","-")}~${this.userData.id}`}}
</div>

               

                <!-- output selection -->
                <div class="mt-5 row">
                    <div class="col-md-7">
                        <label for="rington">Ringtone Devices : </label>
                        <br>
                        <select class="form-select" id="rington" ref="ringtoneDevices" v-model="selectRingDevice"
                            @change="updateRingtoneDevice()">
                            <option v-for="({ label, dataId }, index) in ringtondeviceList" :key="index" :data-id="dataId"
                                :value="dataId">{{ label }}</option>
                        </select>
                    </div>
                    <div class="col-md-7">
                        <label for="speaker">Speaker Devices : </label>
                        <br>
                        <select id="speaker" class="form-select" ref="speakerDevices" v-model="selectSoundDevice"
                            @change="updateOutputDevice()">
                            <option v-for="({ label, dataId }, index) in speakerdeviceList" :key="index" :data-id="dataId"
                                :value="dataId">{{ label }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- make a call -->
            <div class="col-md-6">

                <!-- input for call -->
                <form class="row" v-if="!incomingCall">
                    <h1>Make a Call</h1>
                    <div class="col-md-7">
                        <label for="phone">Calling ID Number : </label>
                        <br>
                        <input class="form-control" type="text" id="phone" v-model="phoneInput" placeholder="Make a call">
                    </div>
                    <div class="col-md-7 mt-2">
                        <div class="d-flex justify-content-around">
                            <button class="btn btn-danger col-3" v-if="isHangUpShow"
                                @click="call.disconnect(), isOpenTosted = false">Hang Up</button>
                            <button class="btn btn-success col-3" v-else type="submit"
                                @click.prevent="makeCall()">Call</button>
                        </div>
                    </div>
                </form>
                <br>

                <!-- incoming call  -->
                <div class="row" v-if="incomingCall">

                    <div class="col-md-7">
                        <h2>Incoming Call Controls</h2>

                        <p>
                            Incoming Call from <span>{{ callFrom }}</span>
                        </p>
                    </div>

                    <div class="col-md-7">
                        <div class="d-flex justify-content-around">
                            <button class="btn btn-success col-3" v-if="!isIncomingHangUp"
                                @click="acceptIncomingCall(call)">Accept</button>
                            <button class="btn btn-warning col-3" v-if="!isIncomingHangUp"
                                @click="rejectIncomingCall(call)">Reject</button>
                            <button class="btn btn-danger col-3" v-if="isIncomingHangUp"
                                @click="hangupIncomingCall(call)">Hangup</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="position-fixed top-0 end-0 p-3" style="z-index: 9999" v-if="incomingCall || isOpenTosted">
            <div id="liveToast" class="toast show bg-success" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <strong class="me-auto">Call...</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body text-white">
                    <p v-if="incomingCall" class="m-0">
                        Incoming Call from <span>{{ callFrom }}</span>
                    </p>
                    <p v-else class="m-0">
                        Call To {{ phoneInput }}
                    </p>
                </div>
            </div>
        </div>
    </Layout>
</template>
